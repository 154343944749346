import React from "react";

interface NumberInputProps {
    label: string;
    value: number;
    onChange: (value: number) => void;
    className?: string;
    style?: React.CSSProperties;
    placeholder?: string;
    isRequired?: boolean;
    disabled?: boolean;
}

export function PriceInput(props: NumberInputProps) {
    return (
        <div className="control">
            <div className="control__label">
                {props.label}
                {props.isRequired && <span className="required-star">*</span>}
            </div>
            <input className="control__input" name="main_price" step="1" placeholder={props.placeholder}
                   disabled={props.disabled}
                   onWheel={(e) => {
                       e.preventDefault()
                   }}
                   value={(props.value / 100).toString()}
                   onChange={(e) => {
                       let val = e.currentTarget.value
                       if (val.indexOf('.') !== -1) {
                           let parts = val.split('.')
                           val = parts[0] + "." + parts[1].slice(0, 2)
                       }
                       let num = Number(val)
                       if (isNaN(num)) {
                           num = 0
                       }
                       props.onChange(Number((num * 100).toFixed(10)))
                   }}
            />
        </div>
    )
}